import React from 'react'

import ScrollAnimation from '../../scroll-animation/Container'
import './Hero.less'
import HeroForm from './HeroForm'

const Hero = () => {
  return (
    <ScrollAnimation animateIn={'fadeIn'}>
      <div className="textforlunch-hero">
        <div className="textforlunch-hero__left">
          <HeroForm />
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default Hero
