import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useEffect, useState } from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import PhoneInput, { getCountryCallingCode, parsePhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import { MOBILE_VIEW_BREAKPOINT } from '../../../constants'
import { LenientValidatePhoneNumber, ValidateEmail, ValidateUSPhoneNumber, ValidateZip } from '../../../helpers'
import useHomepage from '../../../hooks/useHomepage'
import ImageMobile from '../../../images/textforlunch-hero2.png'
import Image from '../../../images/textforlunch-hero.png'
import CountryRequirement from '../../../models/CountryRequirement'
import UtmContent from '../../../models/UtmContent'
import { AnalyticsService } from '../../../services'
import { ApiService, UrlService } from '../../../services'
import InlineSpinner from '../../common/InlineSpinner'
import ScrollAnimation from '../../scroll-animation/Container'

type State = {
  phone_number: string
  zip: string
  postCode: string
  phoneNumberValid: boolean
  emailValid: boolean
  email: string
  zipValid: boolean
  loading: boolean
  countryCode: string
  emailMarketingConsent: boolean
}

const HeroForm: React.FC = () => {
  const isMobile = typeof window != 'undefined' && window.innerWidth < MOBILE_VIEW_BREAKPOINT
  const { animationProps, on6ForLunchPage, activeCityName, countryCodeIso3 } = useHomepage()
  const position = 'lunch-for-less'
  const utmContent = new UtmContent({ position: position }).toString()
  const [url, setUrl] = useState('')

  useEffect(() => {
    const utmContent = new UtmContent({ position: 'lunch-for-less' }).toString()
    setUrl(UrlService.signupUrl({ utmContent: utmContent, cityName: activeCityName }))
  }, [])

  const [state, setState] = useState<State>({
    phone_number: '',
    zip: '',
    postCode: '',
    email: '',
    phoneNumberValid: true,
    emailValid: true,
    zipValid: true,
    loading: false,
    countryCode: '',
    emailMarketingConsent: false,
  })

  const { t } = useTranslation()

  const country = new CountryRequirement({ countryCodeIso3 })
  const isZipRequired = country.isZipRequired()
  const countryCode = country.code()
  const isEmailMarketingConsetRequired = country.isEmailMarketingConsetRequired()

  const onClickHandler = () => {
    AnalyticsService.trackEvent({
      action: 'Click',
      category: 'Homepage',
      label: 'Get Started',
    })

    window.ttq.track('SubmitForm', {
      content_id: 'homepage_form',
    })
  }

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const target = e.target
    const value = target.value
    setState({ ...state, [target.name]: value })
  }

  const onPhoneChangeHandler = (v: any) => {
    const phone = v
    setState({ ...state, ['phone_number']: phone })
  }

  const onCountryChangeHandler = (c: any) => {
    const dialCode = getCountryCallingCode(c)
    setState({ ...state, ['countryCode']: dialCode })
  }

  const zipElement = () => {
    const defaultProps = {
      className: `textforlunch-form__input textforlunch-form__input--zip ${
        !state.zipValid && 'textforlunch-form__input__error'
      }`,
      type: 'text',
      onChange: onChangeHandler,
    }

    if (isZipRequired) {
      return <input {...defaultProps} name="zip" value={state.zip} placeholder={t('Zip Code')} />
    } else {
      return <input {...defaultProps} name="postCode" value={state.postCode} placeholder={t('Post Code')} />
    }
  }

  const phoneNumberElement = () => {
    const inputProps = {
      className: `textforlunch-form__input ${!state.phoneNumberValid && 'textforlunch-form__input__error'} ${
        !state.phoneNumberValid && 'flag-error'
      }`,
      name: 'phone_number',
      required: true,
    }

    const flag_class = !state.phoneNumberValid ? 'textforlunch-form__input__error' : ''

    return (
      <PhoneInput
        countrySelectProps={{ className: 'flag-dropdown' }}
        containerStyle={{ className: 'form-control' }}
        inputProps={inputProps}
        inputClass={'textforlunch-form__input'}
        defaultCountry="US"
        value={state.phone_number}
        disableCountryGuess={true}
        onChange={onPhoneChangeHandler}
        placeholder={t('Phone Number')}
        onCountryChange={onCountryChangeHandler}
        className={`textforlunch-form__input ${flag_class}`}
      />
    )
  }

  const emailElement = () => {
    const defaultProps = {
      className: `textforlunch-form__input textforlunch-form__input--zip ${
        !state.emailValid && 'textforlunch-form__input__error'
      }`,
      type: 'text',
      onChange: onChangeHandler,
    }

    return <input {...defaultProps} name="email" value={state.email} placeholder={t('Email')} />
  }

  const handleSubmitTextForLunch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return

    const parsedPhoneNumber = parsePhoneNumber(state.phone_number)
    const zipValid = ValidateZip(state.zip)

    let dialCode = ''
    let phoneNumberValid = false

    if (parsedPhoneNumber) {
      dialCode = parsedPhoneNumber.countryCallingCode.toString()

      phoneNumberValid =
        dialCode === '1'
          ? ValidateUSPhoneNumber(state.phone_number)
          : LenientValidatePhoneNumber(state.phone_number, dialCode)

      setState({ ...state, phoneNumberValid })
    }
    if (phoneNumberValid && zipValid) {
      setState({ ...state, phoneNumberValid, zipValid, loading: true })

      ApiService.createPhoneNumberLead(
        {
          phone_number: state.phone_number.slice(dialCode.length + 1),
          zip: state.zip,
          marketing_consent: true,
          utm_content: utmContent,
          country_code: dialCode,
        },
        {
          platform: 'web',
          location: 'homepage_textforlunch',
        },
      ).finally(() => {
        window.location.href = url
      })
    } else {
      setState({ ...state, phoneNumberValid, zipValid })
    }
  }

  const handleSubmit6ForLunch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (state.loading) return

    const zipValid = ValidateZip(state.zip)
    const emailValid = ValidateEmail(state.email)

    if (emailValid && zipValid) {
      setState({ ...state, emailValid, zipValid, loading: true })

      ApiService.createInvite(
        {
          email: state.email,
          zip: state.zip,
          post_code: state.postCode,
          country_code: countryCode,
          utm: utmContent,
          email_marketing_consent: !isEmailMarketingConsetRequired || state.emailMarketingConsent,
        },
        {
          platform: 'web',
          location: 'homepage_6forlunch',
        },
      ).finally(() => {
        window.location.href = url
      })
    } else {
      setState({ ...state, emailValid, zipValid })
    }
  }

  const onSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    if (on6ForLunchPage) {
      handleSubmit6ForLunch(e)
    } else {
      handleSubmitTextForLunch(e)
    }
  }

  return (
    <ScrollAnimation {...animationProps} animateIn={isMobile ? 'fadeInUpForm' : 'defaultAnimation'}>
      <div className="textforlunch-form">
        <ScrollAnimation {...animationProps} animateIn={isMobile ? 'defaultAnimation' : 'fadeIn'}>
          <div className="textforlunch-form__title">
            {t('hero-title-line-one')}
            <br />
            {t('hero-title-line-two')}
          </div>
        </ScrollAnimation>
        <div className="textforlunch-form__form">
          <form className="form" onSubmit={onSubmitHandler}>
            {!on6ForLunchPage && phoneNumberElement()}
            {!on6ForLunchPage && !state.phoneNumberValid && (
              <div className="textforlunch-form__form__error-message">
                <FiAlertCircle /> {t('Invalid phone number')}
              </div>
            )}
            {on6ForLunchPage && emailElement()}
            {!state.emailValid && on6ForLunchPage && (
              <div className="textforlunch-form__form__error-message">
                <FiAlertCircle /> {t('Invalid email address')}
              </div>
            )}
            {zipElement()}
            {!state.zipValid && (
              <div className="textforlunch-form__form__error-message">
                <FiAlertCircle /> {isZipRequired ? t('Invalid zip code') : t('Invalid post code')}
              </div>
            )}
            <button className="textforlunch-form__button" type="submit" onClick={onClickHandler}>
              {state.loading ? (
                <InlineSpinner />
              ) : on6ForLunchPage ? (
                '' || t('Browse Restaurants')
              ) : (
                '' || t('Text us for lunch')
              )}
            </button>
          </form>
          {!on6ForLunchPage && (
            <div className="textforlunch-form__disclaimer">
              {t(
                'By signing up via text you agree to receive promotional offers at the phone number provided. Standard message rates apply. Unsubscribe at anytime by messaging STOP.',
              )}
            </div>
          )}
          <img src={Image} className="textforlunch-hero__image" />
          <img src={ImageMobile} className="textforlunch-hero__image-mobile" />
        </div>
      </div>
    </ScrollAnimation>
  )
}

export default HeroForm
