import React from 'react'

import './RestaurantLogos.less'

const RestaurantLogos = () => {
  return (
    <div className="restaurant-logos">
      <img className="restaurant-logos__logo-1" src="/images/restaurants-logos/textforlunch/logo-sweetgreen.png" />
      <img className="restaurant-logos__logo-2" src="/images/restaurants-logos/textforlunch/logo-chipotle.png" />
      <img className="restaurant-logos__logo-4" src="/images/restaurants-logos/textforlunch/logo-little-beet.png" />
      <img className="restaurant-logos__logo-5" src="/images/restaurants-logos/textforlunch/logo-lukes.png" />
      <img className="restaurant-logos__logo-6" src="/images/restaurants-logos/textforlunch/logo-just-salad.png" />
      <img className="restaurant-logos__logo-7" src="/images/restaurants-logos/textforlunch/logo-naya.png" />
      <img
        className="restaurant-logos__logo-chipotle-circle"
        src="/images/restaurants-logos/textforlunch/chipotle_circle_logo.png"
      />
      <img className="restaurant-logos__logo-8" src="/images/restaurants-logos/textforlunch/logo-shake-shack.png" />
    </div>
  )
}

export default RestaurantLogos
