import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import SEO from '../seo'
import Hero from './hero/Hero'
import RestaurantLogos from './restaurant-logos/RestaurantLogos'

const TextForLunchContainer: React.FC<RouteProps<'/textforlunch' | '/6forlunch'>> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO title="Text For Lunch" />
      <Hero />
      <RestaurantLogos />
    </>
  )
}

export default TextForLunchContainer
